<template>
  <div>
    <div
      class="width-full titbg color-white p15 flex align-items-center b-b-1"
      @click="toggleExpand"
    >
      <i
        :class="expanded ? 'actives' : ''"
        class="el-icon-caret-right fs25 boxsa"
      />
      <div class="pl30 fs22">{{ title }}</div>
      <div></div>
    </div>
    <CollapseTransition>
      <div class="description" v-show="expanded">
        <slot></slot>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import CollapseTransition from "./colapse";
export default {
  components: {
    CollapseTransition,
  },
  props: ["title", "croped"],
  data() {
    return {
      expanded: true,
    };
  },
  mounted() {
    this.expanded = this.croped;
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
.titbg {
  background: #000;
}
.boxsa {
  transition: transform 0.3s;
}
.actives {
  transform: rotate(90deg);
}
</style>