<template>
  <div>
    <div v-if="!isMobile">
      <div class="aboutones">
        <div v-for="(item, index) in videoList" :key="index">
          <videoindex
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
          ></videoindex>
        </div>
        <div class="titlechange"></div>
        <div
          class="sones color-white letter-5"
          :class="{ 'animate__bounceInLeft animate__animated': pop }"
        >
          <div class="fs60 fw600">{{ titlist[active].tit }}</div>
          <div class="fs34 pt40 fw600">{{ titlist[active].desc }}</div>
        </div>
      </div>

      <div class="imgbox">
        <img :src="p2" alt="" />
      </div>
      <!-- 人才招聘 -->
      <div class="bg-black pb50">
        <div class="box1200auto">
          <div class="pt30 fs30 color-white pb50">人才招聘</div>
          <div v-for="(item, index) in peopList" :key="index">
            <collitems
              :title="
                item.title + ' ( ' + item.address + ' / ' + item.num + '人 )'
              "
              :croped="false"
            >
              <div class="flex color-white">
                <!-- 招聘职位 -->
                <div
                  class="width-50 pr50"
                  v-for="(amt, dex) in item.job"
                  :key="dex"
                >
                  <!-- 岗位职责，任职要求 -->
                  <div class="pt30 fs22 color-white pb50">
                    {{ amt.tit + " : " }}
                  </div>

                  <!-- 要求条款  -->
                  <div
                    class="pt5 pb20 lh-30"
                    v-for="(a, b) in amt.list"
                    :key="b"
                  >
                    {{ a.content }}
                  </div>
                </div>
              </div>
            </collitems>
          </div>

          <div class="color-white">
            <div class="fs25 pt50">{{ meslis.tels }}</div>
            <div class="fs25 pt10">{{ meslis.yx }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isMobile">
      <div class="aboutones">
        <div v-for="(item, index) in videoList" :key="index">
          <mobilenewtest
            :id="'ads' + index"
            :item="item"
            :ref="'deo' + index"
            :isones="index == 0 ? true : false"
          ></mobilenewtest>
        </div>
        <div class="titlechange"></div>
        <div
          class="sones1 width-full color-white letter-5 pl15 pr15"
          :class="{ 'animate__bounceInLeft animate__animated': pop }"
        >
          <div class="fs32 text-center fw600">{{ titlist[active].tit }}</div>
          <div class="fs20 text-center pt40 fw600">
            {{ titlist[active].desc }}
          </div>
        </div>
      </div>

      <div class="imgbox relative">
        <div class="absolute top left pt30 fs30 color-white pl15 pt30">
          发展历程
        </div>
        <img :src="p2" alt="" />
      </div>
      <!-- 人才招聘 -->
      <div class="bg-black pb50">
        <div class="pl15 pr15">
          <div class="pt30 fs30 color-white pb50">人才招聘</div>
          <div v-for="(item, index) in peopList" :key="index">
            <mobilecollitems
              :title="
                item.title + ' ( ' + item.address + ' / ' + item.num + '人 )'
              "
              :croped="false"
            >
              <div class="color-white">
                <!-- 招聘职位 -->
                <div class="" v-for="(amt, dex) in item.job" :key="dex">
                  <!-- 岗位职责，任职要求 -->
                  <div class="pt10 fs22 color-white pb10">
                    {{ amt.tit + " : " }}
                  </div>

                  <!-- 要求条款  -->
                  <div
                    class="pt5 pb5 lh-30"
                    v-for="(a, b) in amt.list"
                    :key="b"
                  >
                    {{ a.content }}
                  </div>
                </div>
              </div>
            </mobilecollitems>
          </div>

          <div class="color-white">
            <div class="fs20 pt50">{{ meslis.tels }}</div>
            <div class="fs20 pt10">{{ meslis.yx }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import hendtitle from "@/components/pc/hendtitle";
// import jjfnhend from "@/assets/images/jjfnhend.jpg";
// import mobilehendtitle from "@/components/pc/mobilehendtitle";
import videoindex from "@/components/pc/videoindex";
import collitems from "@/components/pc/toopen/collitems";
// import video1 from "../../assets/images/detileimg/aboutus.mp4";
import p1 from "@/assets/images/about/p1.jpg";
import p2 from "@/assets/images/about/p2.jpg";
import p3 from "@/assets/images/about/p3.jpg";

// 手机
import mobilenewtest from "@/components/mobile/mobilenewtest";
import mp1 from "@/assets/images/about/mp1.jpg";
import mp2 from "@/assets/images/about/mp2.jpg";
import mobilecollitems from "@/components/mobile/toopen/collitems";

import { look_mixin } from "@/utils/looktime.js";
export default {
  components: {
    // hendtitle,
    // mobilehendtitle,
    mobilecollitems,
    mobilenewtest,
    videoindex,
    collitems,
  },
  mixins: [look_mixin],
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
      meslis: {
        tels: "招聘电话 : 021-3750 9071",
        yx: "邮箱 : hr@zoesolar.com",
      },
      videoList: [
        {
          id: 1,
          title: "",
          desc: "",
          src: p1,
          isvideo: false,
          pages: false,
          runurl: "/about",
          img: "img1",
        },
      ],
      p2: p2,
      p3: p3,
      active: 0,
      imtvals: null,
      titlist: [
        { id: 0, tit: "愿 景", desc: "创新绿色能源 成就智慧生活" },
        { id: 0, tit: "使 命", desc: "成为改变能源结构的全球化企业" },
        { id: 0, tit: "价 值 观", desc: "正直 创新 协同 共享" },
      ],
      pop: false,
      peopList: [
        {
          id: 4,
          title: "储能产品经理",
          address: "上海",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责储能市场政策、发展趋势、产品迭代、客户需求、经济性等进行调研与预测，为销售策略提供建议；",
                },
                {
                  content:
                    "2、根据了解的行业信息与判断，对储能产品的产品规划、研发方向、系统解决方案、储能系统应用场景设定等提供参考；",
                },
                {
                  content:
                    "3、深度参与储能项目产品研发、系统集成、客户解决方案沟通等环节，从市场、技术支持角度不断完善产品种类、功能、材质等，以满足客户需求，并兼顾性价比；",
                },
                {
                  content: "4、其他上级主管安排的工作。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、本科及以上，电子、电气、电化学等专业，对电力电子类产品管理流程熟悉；",
                },
                {
                  content:
                    "2、有储能集成系统（EMS、BMS等）产品管理经验，了解储能行业技术与市场优先；",
                },
                {
                  content: "3、良好的沟通、组织、抗压能力，执行力强。",
                },
              ],
            },
          ],
        },
        {
          id: 5,
          title: "销售总监/经理",
          address: "上海",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、国内外储能及相关市场发展动向及行业从业者竞争力分析评估并制定有效的营销策略；",
                },
                {
                  content: "2、负责营销团队的能力建设及有序管理；",
                },
                {
                  content: "3、负责年度营销目标和价值传播目标的规划及实现；",
                },
                {
                  content:
                    "4、以市场为导向，积极推动内部信息透明无碍沟通，市场需求及时反馈至生产、技术部门并获得相应有效的支持，为客户提供有竞争力的解决方案。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、大学本科及以上学历，电池、电化学、电力电子、电力系统等相关专业，熟悉电池系统、BMS、PCS、储能系统或电力系统等知识；",
                },
                {
                  content:
                    "2、6年以上销售管理工作经验，其中3年以上的锂电池、储能系统、电力系统、新能源、分布式发电或微电网等行业工作经验；",
                },
                {
                  content:
                    "3、熟悉储能、新能源行业发展情况，熟悉国内外能源客户业务运作模式，在五大四小及电网领域有较好的客户资源积累；",
                },
                {
                  content:
                    "4、工作积极，乐于较为频繁的出差，能承受一定的工作压力，有强烈的责任心和团队合作精神；",
                },
                {
                  content: "5、具备一定的英语读写基础。",
                },
              ],
            },
          ],
        },

        {
          id: 10,
          title: "总助",
          address: "上海总部",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、协助总经理制定、贯彻、落实公司各项经营发展战略、计划，实现公司经营管理目标。",
                },
                {
                  content:
                    "2、负责公司内部部门的协调、公司制度的制定、协助总经理做好对外经营、决策工作。",
                },
                {
                  content:
                    "3、协助总经理调查研究，汇报公司经营管理情况并提出处理意见或建议，供总经理决策。",
                },
                {
                  content:
                    "4、协助总经理做好经营服务及跟踪公司经营目标达成情况，提供分析意见及改进建议。",
                },
                {
                  content:
                    "5、组织编制公司年、季、月度经营计划，经总经理审批后，监督、协调各职能部门执行。",
                },
                {
                  content:
                    "6、负责下属各职能部门的各项管理工作，并督促、检查、落实贯彻执行情况。",
                },
                {
                  content:
                    "7、辅助总经理，进行公司重要任务下达、客户接待、合同谈判及签订等。",
                },
                {
                  content:
                    "8、配合总经理处理外部公共关系（政府、重要客户等）。",
                },
                {
                  content:
                    "9、在总经理授权内，负责日常事务的审核、日常事务流程制度的维护、完善、开展并组织公司日常工作的绩效考核。",
                },
                {
                  content:
                    "10、负责上级领导机关或兄弟单位领导的接待、考察、参观工作。",
                },
                {
                  content:
                    "11、负责公司内外的公文办理，解决来信、来访事宜，及时处理并向总经理汇报。",
                },
                {
                  content: "12、起草公司各阶段工作总结和其他正式文件。",
                },
                {
                  content: "13、落实总经理交办的其他工作内容。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content: "1、本科以上学历、 MBA，硕士，博士优先；",
                },
                {
                  content:
                    "2、工商管理、经济、市场营销等相关专业，5年以上总助工作经验；",
                },
                {
                  content:
                    "3、有较强的组织、协调、沟通、领导能力及敏锐洞察力，很强的判断与决策能力，计划和执行能力；",
                },
                {
                  content: "4、良好的团队协作精神，为人诚实可靠、品行端正；",
                },
                {
                  content: "5、思路敏捷，有勇于开拓的精神和强烈的事业责任心；",
                },
                {
                  content: "6、能适应不定时出差。",
                },
              ],
            },
          ],
        },

        {
          id: 11,
          title: "国际法务经理/主管",
          address: "上海总部",
          num: 1,
          job: [
            {
              tit: "岗位职责",

              list: [
                {
                  content:
                    "1、为公司持续发展及重大经营决策提供法律咨询、法律支持；",
                },
                {
                  content:
                    "2、识别、评估公司日常经营中的法律和合规风险，提出评审意见；",
                },
                {
                  content: "3、参与公司重大诉讼及纠纷处理；",
                },
                {
                  content: "4、参与重大项目的谈判，提供法律建议；",
                },
                {
                  content:
                    "5、根据公司具体经营情况制定个性化的法律风险防范机制并形成管理制度；",
                },
                {
                  content: "6、负责公司国际化建设过程中的法律支持工作。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、法律相关专业硕士以上学历，国际经济法专业优先，通过国家司法考试；",
                },
                {
                  content:
                    "2、3年以上大型制造业或新能源及相关行业的同岗位经验；",
                },
                {
                  content:
                    "3、熟悉国际业务，熟悉欧盟法与国际法，有欧美企业工作经验者优先考虑；",
                },
                {
                  content:
                    "4、稳重踏实，工作细心严谨，反应敏捷，具有较强的表达能力、沟通能力、谈判能力、协调管理能力及抗压能力；",
                },
                {
                  content:
                    "5、有较强的书面和口头表达能力，英语可作为工作语言。",
                },
              ],
            },
          ],
        },

        {
          id: 12,
          title: "结构工程师",
          address: "常州",
          num: 1,
          job: [
            {
              tit: "岗位职责",

              list: [
                {
                  content:
                    "1、负责锂电池pack设计、储能系统产品的结构设计，包括机械设计、部件选型、设计图纸输出；",
                },
                {
                  content:
                    "2、负责储能系统电池控制柜、配电柜、系统接入柜等电气成套柜产品的结构设计，包括元器件布局、布线和设计图纸输出；",
                },
                {
                  content: "3、负责储能的散热仿真、设计；",
                },
                {
                  content:
                    "4、解决产品生产装配过程中的技术问题，参与产品的试制，调试，转产工作；",
                },
                {
                  content:
                    "5、对产品持续优化，通过改进设计方案，改进材料，增加零件的通用性控制成本。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、机械类本科及以上学历，5年以上机械结构设计经验，熟知产品结构开发设计流程，独立完成设计；",
                },
                {
                  content:
                    "2、3年以上大中型产品钣金设计经验，熟悉激光，数冲，折弯，焊接工艺；",
                },
                {
                  content: "3、熟练应用Pro/E，Auto CAD等设计软件；",
                },
                {
                  content:
                    "4、有电池pack结构设计、储能集装箱结构设计经验者优先；",
                },
                {
                  content: "5、工作态度严谨，有良好团队协作能力。",
                },
              ],
            },
          ],
        },

        {
          id: 13,
          title: "电气工程师",
          address: "常州",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责储能高压电气系统（含PCS、电池系统、高压附件、系统控制）电气方案设计、电气部件、接插件、线缆的选型及电气图纸、线束图纸绘制；",
                },
                {
                  content:
                    "2、负责储能产品出厂测试指导及改进，电气部分性能及成本优化；",
                },
                {
                  content:
                    "3、对储能产品调试中遇到的技术问题进行分析，协助生产部门解决出厂测试问题；",
                },
                {
                  content:
                    "4、负责编制储能产品技术文件、产品使用说明书等文件资料；",
                },
                {
                  content: "5、负责储能电气安全、1500V高压等先进技术研究；",
                },
                {
                  content: "6、研究电化学储能行业相关标准，并实际应用；",
                },
                {
                  content: "7、执行电气零部件的可靠性和特性的试验评估工作；",
                },
                {
                  content:
                    "8、收集行业内新技术、新工艺、新配置等信息，促进工艺技术水平和产品质量提高。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、本科以上学历，具备5年以上电气设计经验，2年以上储能电池系统相关经验；",
                },
                {
                  content:
                    "2、熟悉电气设计规范、电气原理图、线束图、接线图等；",
                },

                {
                  content:
                    "3、熟悉EMC相关知识、相关原理及测试方法，并运用到系统设计中，规避及解决产品运行中的EMC问题；",
                },
                {
                  content: "4、熟悉BMS相关知识；熟悉PCS相关知识；",
                },
                {
                  content:
                    "5、熟悉零部件生产制造工艺及储能系统的制造工艺流程；",
                },

                {
                  content: "6、熟悉电化学储能或电力传输相关国内外标准；",
                },
                {
                  content: "7、 熟练掌握Autocad等电气设计软件的应用；",
                },
                {
                  content:
                    "8、具备储能系统电气设计经验、熟悉电池管理系统架构及相关电气件选型标准者优先；",
                },
                {
                  content: "9、沟通能力强，具有良好的团队合作精神和创新意识。",
                },
              ],
            },
          ],
        },
        {
          id: 14,
          title: "储能PCS硬件工程师",
          address: "常州",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content: "1、负责储能PCS、等产品硬件开发工作；",
                },
                {
                  content:
                    "2、负责新产品原理图设计，电路仿真，器件选型与设计等；",
                },
                {
                  content: "3、设计验证测试，测试问题解决，样机调试。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、本科及以上学历；电气工程、电力电子等相关专业，5年以上硬件开发经验；",
                },
                {
                  content:
                    "2、熟悉常用元器件的选择和使用、数字控制，CPUDSP控制单板设计；",
                },

                {
                  content:
                    "3、能熟练使用matlab/ad/pads/mathcad等软件，会电路仿真，熟悉使用通用仪器；",
                },
                {
                  content: "4、掌握相关的磁性元件设计。",
                },
              ],
            },
          ],
        },
        {
          id: 15,
          title: "硬件工程师",
          address: "常州",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责硬件元器件选型、设计，完成硬件开发及过程文档的编写，负责原有产品的硬件设计变更和版本更替；",
                },
                {
                  content:
                    "2、单板原理图设计、协助PCB layout以及单板调试；参与编制硬件设计书和硬件单元电路设计应用规范等；",
                },
                {
                  content:
                    "3、协助设计规格的建立和测试计划的制定，协助完成DUT测试以及其他测试认证工作；",
                },
                {
                  content: "4、协助业务部门现场排查设备问题和提供技术支持；",
                },
                {
                  content:
                    "5、负责产品BOM整理和发布，完成元器件编码；完成部门安排的工作任务。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、本科及以上学历，有储能系统产品/嵌入式等系统硬件开发的相关经验与经历；",
                },
                {
                  content:
                    "2、具有一定的工业设计知识，熟悉硬件开发流程，良好的电子电路分析能力；",
                },

                {
                  content:
                    "3、熟练使用protel99se或者altium designer，PCB板的设计、制作，焊接、调试电路板等技术，有较强的模拟/数字电路硬件设计调试能力，熟悉嵌入式系统原理；",
                },
                {
                  content:
                    "4、有较强的学习能力，具备灵活应变，良好的英文阅读能力，工作认真，善于沟通。",
                },
              ],
            },
          ],
        },
        {
          id: 16,
          title: "储能PCS软件工程师",
          address: "常州",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content: "1、负责逆变器、储能PCS、软件总体方案设计； ",
                },
                {
                  content: "2、负责DSP软件设计、开发；",
                },
                {
                  content: "3、负责嵌入式软件设计和调试优化；",
                },
                {
                  content:
                    "4、负责具体软件模块设计与实现，控制算法的设计选型以及与硬件工程师共同确定系统方案。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content: "1、 电力电子、电气工程、自动化等相关专业； ",
                },
                {
                  content:
                    "2、 具备5年及以上新能源从业经验，熟悉相关DC/AC算法；",
                },

                {
                  content: "3、 精通TI系列CPU的C语言，了解相关汇编语言；",
                },
                {
                  content:
                    "4、 熟悉新能源领域（风电、光伏、储能或变频器等）领域的控制策略；",
                },
                {
                  content:
                    "5、 熟练掌握DSP等嵌入式系统,具备专业英文材料阅读能力；",
                },
                {
                  content: "6、精通电力电子储能、逆变等相关核心控制算法。",
                },
              ],
            },
          ],
        },
        {
          id: 17,
          title: "储能解决方案总监",
          address: "上海",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责储能系统集成设计相关管理工作，具有工程管理方面及技术方面的前瞻性；",
                },
                {
                  content:
                    "2、完成储能技术文档的编写、归档和更新，及时跟踪业界新技术、新工艺、新产品动态；",
                },
                {
                  content:
                    "3、光伏储能项目的需求调查、评估、配套设备选型、系统集成设计、方案设计和技术服务、招投标文件编制等；",
                },
                {
                  content:
                    "4、对于现场变更进行技术确认，支持客户现场系统安装调试、问题排查、信号诊断等；",
                },
                {
                  content:
                    "5、储能各种应用模型的分析和设计优化，主导并推进集成系统的核心指标优化和提升；",
                },
                {
                  content:
                    "6、组织系统集成的工程交付和异常的协调解决，对验收中出现的问题及时反馈，并实施整改和复验；",
                },
                {
                  content:
                    "7、能独立完成储能技术应答，方案设计、预算及报价、项目跟踪总结，把控好整个工程质量；",
                },
                {
                  content: "8、上级安排的其他工作等。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、 本科及以上学历，电力电子或电力系统相关专业，具备注册电气工程师证书者优先；",
                },
                {
                  content:
                    "2、 具备丰富的风光储项目、光储充项目、或智能微网、或电池储能项目、或集装箱储能系统设计工作经验3年以上，可完成储能系统架构设计和产品系统集成；",
                },

                {
                  content:
                    "3、 熟练使用系统设计软件，具有Matlab系统仿真能力和google sketchup设计能力的优先；",
                },
                {
                  content:
                    "4、 具备良好的组织、计划、执行、沟通、协调、分析和学习能力，良好的责任心、事业心和团队合作精神。",
                },
              ],
            },
          ],
        },
        // {
        //   id: 18,
        //   title: "储能解决方案经理",
        //   address: "上海",
        //   num: 1,
        //   job: [
        //     {
        //       tit: "岗位职责",
        //       list: [
        //         {
        //           content:
        //             "1、负责储能市场政策、发展趋势、产品迭代、客户需求、经济性等进行调研与预测，为销售策略提供建议；",
        //         },
        //         {
        //           content:
        //             "2、根据了解的行业信息与判断，对储能产品的产品规划、研发方向、系统解决方案、储能系统应用场景设定等提供参考；",
        //         },
        //         {
        //           content:
        //             "3、深度参与储能项目产品研发、系统集成、客户解决方案沟通等环节，从市场、技术支持角度不断完善产品种类、功能、材质等，以满足客户需求，并兼顾性价比；",
        //         },
        //         {
        //           content: "4、其他上级主管安排的工作。",
        //         },
        //       ],
        //     },
        //     {
        //       tit: "任职要求",
        //       list: [
        //         {
        //           content:
        //             "1、 本科及以上学历，电力电子或电力系统相关专业，具备注册电气工程师证书者优先；",
        //         },
        //         {
        //           content:
        //             "2、 具备丰富的风光储项目、光储充项目、或智能微网、或电池储能项目、或集装箱储能系统设计工作经验3年以上，可完成储能系统架构设计和产品系统集成；",
        //         },

        //         {
        //           content:
        //             "3、 熟练使用系统设计软件，具有Matlab系统仿真能力和google sketchup设计能力的优先；",
        //         },
        //         {
        //           content:
        //             "4、 具备良好的组织、计划、执行、沟通、协调、分析和学习能力，良好的责任心、事业心和团队合作精神。",
        //         },
        //       ],
        //     },
        //   ],
        // },

        {
          id: 19,
          title: "储能解决方案经理",
          address: "上海",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责储能市场政策、发展趋势、产品迭代、客户需求、经济性等进行调研与预测，为销售策略提供建议；",
                },
                {
                  content:
                    "2、根据了解的行业信息与判断，对储能产品的产品规划、研发方向、系统解决方案、储能系统应用场景设定等提供参考；",
                },
                {
                  content:
                    "3、深度参与储能项目产品研发、系统集成、客户解决方案沟通等环节，从市场、技术支持角度不断完善产品种类、功能、材质等，以满足客户需求，并兼顾性价比；",
                },
                {
                  content: "4、其他上级主管安排的工作。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、本科及以上，电子、电气、电化学等专业，对电力电子类产品管理流程熟悉；",
                },
                {
                  content:
                    "2、有储能集成系统（EMS、BMS等）产品管理经验，了解储能行业技术与市场优先；",
                },

                {
                  content: "3、良好的沟通、组织、抗压能力，执行力强。",
                },
              ],
            },
          ],
        },

        {
          id: 20,
          title: "产品营销经理/主管",
          address: "上海",
          num: 1,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、站在市场角度，运用用户思维收集需求及感受，衔接研发及产品部门，提供改进意见，定位产品卖点；",
                },
                {
                  content: "2、用户洞察，规划营销及产品传播策略；",
                },
                {
                  content:
                    "3、收集市场数据（竞品分析等），形成产品数据库，运用算法做出科学的结论建议。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content: "1、本科及以上学历，电气自动化及相关专业；",
                },
                {
                  content:
                    "2、熟悉市场洞察、产品营销和解决方案推广的方法，具备经营意识；",
                },

                {
                  content: "3、具备需求调研和竞争分析能力，项目管理能力；",
                },
                {
                  content: "4、逻辑严密，具备良好的表达和文档撰写能力；",
                },
                {
                  content:
                    "5、具备良好的学习能力，能够快速深入掌握陌生领域知识；",
                },
                {
                  content:
                    "6、富有敬业精神，有责任心和进取心，善于合作，抗压能力强。",
                },
              ],
            },
          ],
        },
        {
          id: 21,
          title: "工商业储能销售经理",
          address: "上海",
          num: 10,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责区域内工商业储能产品销售及相关市场发展动向及行业从业者竞争力分析评估并制定有效的营销策略；",
                },
                { content: "2、负责营销团队的能力建设及有序管理；" },
                { content: "3、负责年度营销目标和价值传播目标的规划及实现；" },
                {
                  content:
                    "4、以市场为导向，积极推动内部信息透明无碍沟通，市场需求及时反馈至生产、技术部门并获得相应有效的支持，为客户提供有竞争力的解决方案。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、大学本科及以上学历，市场营销、国际贸易等相关专业；",
                },
                {
                  content:
                    "2、3年以上销售管理工作经验，其中1年以上以上新能源行业销售经验，有储能系统集成产品、电池、组件、逆变器、支架、箱变、风机等销售工作经验优先考虑；",
                },
                {
                  content:
                    "3、熟悉储能、新能源行业发展情况，熟悉国内外能源客户业务运作模式，工商业分布式光储项目端有较好的客户资源积累；",
                },
                {
                  content:
                    "4、工作积极，乐于较为频繁的出差，能承受一定的工作压力，有强烈的责任心和团队合作精神；",
                },
                { content: "5、具备一定的英语读写基础。" },
              ],
            },
          ],
        },

        {
          id: 22,
          title: "储能销售经理",
          address: "上海",
          num: 3,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、区域内储能及相关市场发展动向及行业从业者竞争力分析评估并制定有效的营销策略；",
                },
                { content: "2、负责营销团队的能力建设及有序管理；" },
                { content: "3、负责年度营销目标和价值传播目标的规划及实现；" },
                {
                  content:
                    "4、以市场为导向，积极推动内部信息透明无碍沟通，市场需求及时反馈至生产、技术部门并获得相应有效的支持，为客户提供有竞争力的解决方案。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、大学本科及以上学历，市场营销、国际贸易等相关专业；",
                },
                {
                  content:
                    "2、3年以上销售管理工作经验，其中1年以上新能源行业销售经验，有储能系统集成产品、电池、组件、逆变器、支架、箱变、风机等销售工作经验优先考虑；",
                },
                {
                  content:
                    "3、熟悉储能、新能源行业发展情况，熟悉国内外能源客户业务运作模式，在五大四小电网及工商业分布式光储项目领域有较好的客户资源积累；",
                },
                {
                  content:
                    "4、工作积极，乐于较为频繁的出差，能承受一定的工作压力，有强烈的责任心和团队合作精神；",
                },
                { content: "5、具备一定的英语读写基础。" },
              ],
            },
          ],
        },

        {
          id: 23,
          title: "光伏项目开发经理",
          address: "六盘水",
          num: 3,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责光伏，风能发电站项目前期开发，进行项目开发前期选址、布局、投资环境考察，以及政府初期沟通等工作、项目申报直至取得备案或核准；",
                },
                {
                  content:
                    "2、负责统筹协调内外资源，确保项目的预可研、可研申报、项目核准、并网接入等工作。负责统筹协调资源，进行项目的选址、土地租用、项目申报的前期开发工作；取得项目所需的国土预审、地质灾害、水土保持、环评、文物、电力接入方案批复等电站建设、并网接入所需要的各项核准和许可；",
                },
                {
                  content:
                    "3、建立电站合作开发伙伴模式，采取合作、合资等形式推进电站项目的开发、建设、销售。在专业技术人员的协助下，完成电站投资分析报告；",
                },
                {
                  content:
                    "4、考察、评估电站项目开发可行性，组织并参与项目商务谈判，协调各相关部门和机构，签订商务合作协议；洽谈并签订商务合作协议；",
                },
                {
                  content:
                    "5、建立维护区域政府、电力公司、开发商、设计院等网络关系；",
                },
                {
                  content:
                    "6、熟悉了解国家有关太阳能光伏，风能电站的各项政策及地方的政策；充分了解所在区域的太阳能光伏、风能电站的发展规划，市场竞争情况；提供有价值的项目开发计划。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、光伏太阳能行业或新能源行业工程技术或商务背景；大专以上学历；",
                },
                {
                  content:
                    "2、熟悉电站项目前期开发、项目管理、项目投标和开发等相关知识；",
                },
                {
                  content:
                    "3、有复合型夸领域项目开发、投资、政府公关等从业经验者优先考虑；",
                },
                { content: "4、可适应按项目需要的不定期出差；" },
                {
                  content:
                    "5、具备良好的沟通与协调能力，计划与组织能力；愿意接受挑战。",
                },
              ],
            },
          ],
        },

        {
          id: 24,
          title: "光伏销售经理/分布式全国招聘",
          address: "上海",
          num: 3,
          job: [
            {
              tit: "岗位职责",
              list: [
                {
                  content:
                    "1、负责所在区域整县推进、工商业分布式等业务市场拓展；",
                },
                {
                  content:
                    "2、主导项目前期踏勘、收集资料，筛选有开发价值的项目资源；",
                },
                {
                  content:
                    "3、参与项目的可行性研究，提供与规划设计相关的可研资料；",
                },
                {
                  content:
                    "4、主导项目商务谈判，协调各相关部门和机构，并负责起草各类协议、意向、合同等文件；",
                },
                {
                  content:
                    "5、办理项目政府部门的核准、审批、电网接入、开工等手续；",
                },
                {
                  content:
                    "6、负责开发当地渠道商、中间商资源，建立并维护当地政府及各相关部门的关系。",
                },
              ],
            },
            {
              tit: "任职要求",
              list: [
                {
                  content:
                    "1、大专及以上学历，电力、土木、市场营销等相关专业优先；",
                },
                {
                  content:
                    "2、熟悉项目开发前期工作流程，了解国家和地方项目开发、电网电价等相关法规政策；",
                },
                {
                  content:
                    "3、具有丰富的市场开发相关知识，具备独立的项目前期投资分析、商务谈判以及编制分析报告的能力；",
                },
                {
                  content:
                    "4、有成功开发分布式光伏项目经验，有一定的中间商或项目资源储备；",
                },
                {
                  content: "5、具有较强的沟通协调能力和执行力，适应频繁出差。",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.deo0[0].videoplay();
      // this.videoplayer = this.$refs.video1;
      // this.videoplayer2 = this.$refs.video2;
      // this.videoplayer3 = this.$refs.video3;
    }, 500);
    this.imtvals = setInterval(() => {
      this.changes();
    }, 5000);

    if (this.isMobile) {
      this.p2 = mp2;
      this.videoList[0].src = mp1;
      this.p1 = mp1;
    }
  },
  beforeDestroy() {
    clearInterval(this.imtvals);
  },
  methods: {
    changes() {
      if (this.active >= 2) {
        this.active = 0;
      } else {
        this.active = this.active + 1;
      }
      this.pop = true;
      setTimeout(() => {
        this.pop = false;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutones {
  position: relative;
  .titlechange {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.3);
  }
  .sones {
    position: absolute;
    top: 50%;
    left: 10%;
  }
  .sones1 {
    position: absolute;
    top: 40%;
    left: 0;
  }
}

.imgbox {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
</style>