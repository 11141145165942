<template>
  <div class="imgbox">
    <video
      v-show="item.isvideo"
      loop
      ref="video1"
      id="video"
      webkit-playsinline
      playsinline
      :poster="item.img ? item.img : ''"
      :muted="true"
      preload="metadata"
      style="clear: both; display: block; margin: auto"
      :style="styleObj"
      :src="item.src"
    ></video>
    <img v-show="!item.isvideo" :src="item.src" alt="" />
    <div class="banner-titles absolute axis-x-center text-shadow color-white">
      <!-- // 正常大标题 -->
      <div v-if="!item.tofidetitle">
        <div class="fs25 width-full text-center hoverclass letter-5">
          {{ item.title }}
        </div>
        <div class="fs16 width-full pt20 text-center hoverclass letter-5">
          {{ item.desc }}
        </div>
      </div>
      <!-- // 否定大标题 -->
      <div v-if="item.tofidetitle">
        <div class="fs25 width-full text-center hoverclass letter-5">
          {{ item.title5 }}
        </div>
        <div class="fs25 width-full text-center hoverclass letter-5">
          {{ item.title6 }}
        </div>
      </div>
    </div>
    <div
      class="videosa flex axis-x-center fs18 text-center"
      :class="item.pages ? '' : 'playnone'"
    >
      <div
        class="letter-5 changheand"
        :class="{ 'animate__animated animate__fadeInDown more ': item.pages }"
        @click="runpage(item, 1)"
      >
        了解更多
      </div>
      <div
        class="letter-5 changheand"
        :class="{ 'animate__animated animate__fadeInDown more1 ': item.pages }"
        @click="runpage(item, 2)"
      >
        联系我们
      </div>
    </div>
    <div
      v-if="isones"
      class="iconfont fs30 color-white icon-xiangxia axis-x-center bottom10 absolute"
    ></div>
  </div>
</template>
  
  <script>
export default {
  props: ["item", "isones"],
  data() {
    return {
      videoplayer: null,
      styleObj: {
        width: "100vw",
        height: "100vh",
      },
    };
  },
  mounted() {
    this.videoplayer = this.$refs.video1;
  },
  methods: {
    runpage(path, other) {
      // console.log("跳转页面，", path);
      let name = "";
      if (other == 1) {
        name = "了解更多";
      }
      if (other == 2) {
        name = "联系我们";
        path.runurl = "/addpreore";
      }
      path.btn_name = name;
      this.$emit("video_runpage", path);
      this.$router.push(path.runurl);
    },
    videoplay() {
      if (this.item.isvideo) {
        this.videoplayer.play();
      }
    },
    videopause() {
      if (this.item.isvideo) {
        this.videoplayer.pause();
      }
    },
  },
};
</script>
  
  <style>
.iconpops {
  animation: move 1.6s linear infinite;
}
@keyframes move {
  0% {
    -webkit-transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-4px);
  }
  50%,
  100% {
    -webkit-transform: translateY(0);
  }
  75% {
    -webkit-transform: translateY(4px);
  }
}
</style>
  
  <style lang="scss" scoped>
.banner-titles {
  top: 15%;
  width: 100%;
  // letter-spacing: 5px;
}
.imgbox {
  width: 100%;
  height: 100vh;
  position: relative;
  .videosa {
    bottom: 10%;
    .more {
      background: #e8e9eb;
      //   padding: 10px 50px;
      width: 150px;
      height: 35px;
      line-height: 35px;
      border-radius: 8px;
      margin-right: 20px;
    }
    .more1 {
      background: #e8e9eb;
      width: 150px;
      height: 35px;
      line-height: 35px;
      border-radius: 8px;
    }
  }
  video {
    width: 100vw;
    height: 100vh;
    display: block;
    object-fit: cover;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}
.playnone {
  display: none;
}
</style>