import { formatterDate } from "@/utils/date.js";
import { add_click, change_looktime, btn_click } from "@/requesturl/user.js";
import { mapGetters } from "vuex";
export const look_mixin = {
  data() {
    return {
      user_lookid: null,
      user_looktime: 0,
      look_inevals: null,
      myLookrouer: {},
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (val, oldVal) {
        this.myLookrouer = val;
      },
    },
  },
  mounted() {
    this.userlook();
    this.look_inevals = setInterval(() => {
      this.user_looktime++;
    }, 1000);
  },
  beforeDestroy() {
    this.changeLookTime();
    clearInterval(this.look_inevals);
  },
  methods: {
    user_btn_click(where, url, name) {
      let timestamp = new Date();
      btn_click({
        click_what: where, // a按钮对应标题
        click_url: url, // 按钮去往的页面路径
        begin_url: this.myLookrouer.path.slice(1), // 当前页面的地址
        begin_name: this.myLookrouer.meta.title, // 当前页面的名字
        click_btn_name: name, // 按钮的名字
        click_time: formatterDate(timestamp, "yyyy-MM-dd hh:mm:ss"),
        click_type: this.isMobile ? "手机" : "电脑",
      });
    },
    //网页埋点
    userlook() {
      let timestamp = new Date();
      add_click({
        clicknum: 1,
        clickname: this.myLookrouer.meta.title,
        type: this.myLookrouer.path.slice(1),
        looktime: 0,
        starttime: formatterDate(timestamp, "yyyy-MM-dd hh:mm:ss"),
        runwhere: this.isMobile ? "手机访问" : "电脑访问",
      }).then((res) => {
        console.log("新增请求成功了", res.data.data.id);
        this.user_lookid = res.data.data.id;
      });
    },
    changeLookTime() {
      change_looktime({
        id: this.user_lookid,
        looktime: this.user_looktime,
      }).then((res) => {
        // console.log("修改请求成功了", res);
      });
    },
  },
};
